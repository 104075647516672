/* Container for user details item */

.user-details-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 5px;
  }
  
  /* Title (e.g., First Name, Email) */
  .user-details-title {
    width: 40%; /* Set a fixed width for labels */
    font-weight: bold;
    color: #333;
  }
  
  /* Value (e.g., user's actual first name, email) */
  .user-details-value {
    color: #555;
  }
  
  /* Input form when editing */
  .user-details-form {
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  /* Form input styling */
  .user-details-input {
    width: 70%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Button to confirm edit */
  .user-details-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  /* Edit button */
  .user-details-edit-btn {
    margin-left: auto;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .user-details-title {
      width: 100%;
    }
  
    .user-details-value, .user-details-input {
      width: 100%;
    }
  
    .user-details-item {
      flex-direction: column;
      align-items: flex-start;
    }
  }



  /* Container for the bill summary modal */
#bill_summary {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    overflow: hidden; /* Make the container scrollable */
    background: rgba(0, 0, 0, 0.5); /* Optional: add a background for better contrast */
    z-index: 1000; /* Ensure it stays above other elements */
  }
  
  /* Blur background overlay */
  .Form_blur_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  
  /* Modal content box */
  .modal-content {
    max-width: 500px;
    margin: auto;
    background: white;
    padding: 20px;
    border-radius: 10px;
    z-index: 10;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Improved shadow for better depth */
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }
  
  /* Title style */
  .modal-content h4 {
    margin-bottom: 20px;
  }
  
  /* Common input styles */
  .modal-input,
  .modal-textarea,
  .modal-select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px; /* Consistent spacing between fields */
  }
  
  /* Specific styles for textarea */
  .modal-textarea {
    height: 100px; /* Set height for textarea */
  }
  
  /* Button container styles */
  .button-container {
    display: flex;
    justify-content: space-between; /* Space between buttons */
    gap: 15px;
  }
  
  /* Button styles */
  .btn {
    flex: 1; /* Equal space for buttons */
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer; /* Pointer cursor for buttons */
  }
  
  .btn-primary {
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
  }
  
  .btn-danger {
    background-color: #dc3545; /* Bootstrap danger color */
    color: white;
  }
  
  .btn:disabled {
    background-color: #ccc; /* Disabled button color */
    cursor: not-allowed; /* Not-allowed cursor for disabled buttons */
  }



  /* Create bill CSS */



  /* Overlay for background blur */
.create-bill-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  /* Main container */
  .create-bill-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 2;
  }
  
  /* Form box */
  .create-bill-box {
    background-color: #fff;
    padding: 30px;
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
        overflow: hidden;
    overflow-y: auto;
    height: 100%;
  }
  
  /* Title */
  .create-bill-title {
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  
  /* Form Group */
  .form-group {
    margin-bottom: 20px;
  }
  
  /* Form Label */
  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  /* Input fields */
  .form-input,
  .form-select,
  .form-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    margin-top: 5px;
  }
  
  .form-input:focus,
  .form-select:focus,
  .form-textarea:focus {
    outline: none;
    border-color: #007bff;
  }
  
  /* Textarea */
  .form-textarea {
    height: 120px;
    resize: none;
  }
  
  /* Actions: Create and Close buttons */
  .form-actions {
    display: flex;
    justify-content: space-between;
    gap:15px;
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
  }
  
  .btn-danger {
    background-color: #dc3545;
    color: white;
    border: none;
  }
  
  .btn-primary:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }

  .dialog-overlay {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .dialog {
    background: #fff;
    padding: 30px 40px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    animation: slideIn 0.3s ease-out;
  }
  
  .dialog h3 {
    font-size: 1.6em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .dialog p {
    font-size: 1em;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .dialog button {
    padding: 10px 20px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    transition: background 0.3s ease, transform 0.1s ease;
    margin: 0 10px;
    min-width: 80px;
  }
  
  /* Styling for 'Yes' button */
  .dialog button.confirm {
    background-color: #4CAF50; /* Green */
    color: white;
  }
  
  .dialog button.confirm:hover {
    background-color: #45A049;
  }
  
  .dialog button.confirm:active {
    background-color: #388E3C;
    transform: scale(0.98);
  }
  
  /* Styling for 'No' button */
  .dialog button.cancel {
    background-color: #f44336; /* Red */
    color: white;
  }
  
  .dialog button.cancel:hover {
    background-color: #e53935;
  }
  
  .dialog button.cancel:active {
    background-color: #d32f2f;
    transform: scale(0.98);
  }
  
  /* Animation for overlay and dialog */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }

  .user-details-label {
    font-size: 0.9em; /* Decrease the font size slightly */
  }
  .user-details-btn {
    margin-right: 0.5rem; /* Adjust as needed */
  }