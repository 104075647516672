
.invoice_main {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    overflow-y: auto; 
}
.closeContainer{   
display: flex;
justify-content: end;
align-items: center;
}
.printBtn{
    color: rgb(0, 98, 255);
    background-color: transparent;
    border:none;
    outline: none;
    font-weight: bolder;
}

.main_container {
   
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    box-sizing: border-box;
}

.sub_container {
    background-color: #fff;
    width: 90%; 
    max-width: 1200px; 
    padding: 2rem; 
    box-sizing: border-box;
    /* margin: 20px 0;  */
}

.invoice_container {
    width: 100% !important;
    /* padding: 2rem;  */
    padding-bottom: 0 !important;
    box-sizing: border-box;
}
.invoice_container2 {
    width: 100% !important;
    /* padding: 2rem;  */
    padding-bottom: 0 !important;
    box-sizing: border-box;
}

.invoice_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid black;
    padding-bottom: 1.5rem;
}


.chip{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 31px;
    width: 69px;
    background-color: #2be243;
    border-radius: 13px;
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
}
.header1, .header2 {
    display: flex;
    flex-direction: column;
}

.header1 {
    align-items: flex-start;
}

.header2 {
    align-items: flex-end;
}

.header2 h1 {
    font-size: 4rem;
}

.header2 h6 {
    font-size: 1.5rem;
    margin: -10px;
}
.header2 h5 {
    font-size: 1.5rem;
}

.adress_Container {
    padding: 1.5rem 0; 
}

.adress {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; 
}

.adress1, .adress2 {
    width: 45%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.invoic_data {

}







.totalContainer {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(3, minmax(50px, 1fr));
    align-items: end;
    justify-content: end;
    padding-right: 1rem;
}

.totalContainer div {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 1.2rem; 
}

.total{
    font-size: 20px;
}


/* Media Queries for smaller screens */
@media (max-width: 768px) {
    .sub_container {
        width: 95%; 
        padding: 1.5rem; 
    }

    .header2 h1 {
        font-size: 3rem; 
    }
    .header2 h6 {
        font-size: 1.2rem; 
    }

    .adress {
        flex-direction: column; 
    }

    .adress1, .adress2 {
        width: 100%; 
    }
    .invoice_details span {
        white-space: nowrap;      
        overflow: hidden;         
        text-overflow: ellipsis;  
        }

    .totalContainer div {
        padding-right: 3rem; 
    }
}

@media (max-width: 480px) {
    .header2 h1 {
        font-size: 2.5rem; 
    }

    .header2 h6 {
        font-size: 1rem; 
    }

    .invoice_container {
        padding: 1rem;
    }
}
